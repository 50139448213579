import * as React from 'react';
import { Helmet } from 'react-helmet';
import Logo from '../components/Logo';

import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { cookieLink, privacyLink } from '../components/Constants';

const AboutPage = () => {
	return (
		<>
			<Helmet>
				<title>Google Feud - About</title>
			</Helmet>
			<Logo />
			<ul
				className="altList"
				id="about"
				style={{
					display: 'inline-block',
					padding: '0 var(--single)',
					maxWidth: '600px',
				}}>
				<li key="l0">
					<i>Google Feud</i> is an original game using real Google search
					results pulled periodically from the Google API. Answers change
					frequently and may differ from search results on your device.
				</li>
				<li key="l1">
					<i>Google Feud</i> is a work of parody and not affiliated with Google
					LLC. "Google" and the Google logo are registered trademarks of Google
					LLC.
				</li>
				<li key="l2">
					<OutboundLink target="_blank" href={privacyLink}>
						Privacy Policy
					</OutboundLink>{' '}
					&middot;{' '}
					<OutboundLink target="_blank" href={cookieLink}>
						Cookie Policy
					</OutboundLink>
				</li>
				<li key="l3">
					&copy; 2013 - 2023{' '}
					<OutboundLink target="_blank" href="https://justinhook.com">
						Justin Hook
					</OutboundLink>
				</li>
			</ul>
		</>
	);
};

export default AboutPage;
