import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

type Props = {
	isFeudle?: boolean;
};
const Logo = ({ isFeudle = false }: Props) => (
	<h1 style={{ textAlign: 'center', margin: 'var(--single)' }}>
		<a href={isFeudle ? '/feudle/' : '/'} target="_self">
			{isFeudle ? (
				<StaticImage
					id="logo"
					style={{
						width: '100%',
						height: '100%',
						maxHeight: '90px',
					}}
					src="../images/feudle.png"
					alt="Feudle"
					loading="eager"
					objectFit="scale-down"
				/>
			) : (
				<StaticImage
					id="logo"
					style={{
						width: '100%',
						height: '100%',
						maxHeight: '100px',
						maxWidth: '500px',
					}}
					src="../images/google_feud.svg"
					alt="Google Feud"
					loading="eager"
					objectFit="scale-down"
				/>
			)}
		</a>
	</h1>
);

export default Logo;
